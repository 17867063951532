html, body {
    margin: 0;
    padding: 0;
}

body {
    font-family: Georgia;
    background: rgb(147, 196, 194);
    background: -moz-linear-gradient(-45deg, rgb(147, 196, 194) 18%, rgb(121, 168, 166) 44%, rgb(117, 163, 163) 49%, rgb(94, 140, 146) 74%, rgb(76, 122, 133) 94%);
    background: -webkit-gradient(left top, right bottom, color-stop(18%, rgb(147, 196, 194)), color-stop(44%, rgb(121, 168, 166)), color-stop(49%, rgb(117, 163, 163)), color-stop(74%, rgb(94, 140, 146)), color-stop(94%, rgb(76, 122, 133)));
    background:-webkit-linear-gradient(-45deg, rgb(183 231 229) 18%, rgb(146 206 204) 44%, rgb(150 195 195) 49%, rgb(122 167 173) 74%, rgb(76, 122, 133) 94%);
    background: -o-linear-gradient(-45deg, rgb(147, 196, 194) 18%, rgb(121, 168, 166) 44%, rgb(117, 163, 163) 49%, rgb(94, 140, 146) 74%, rgb(76, 122, 133) 94%);
    background: -ms-linear-gradient(-45deg, rgb(147, 196, 194) 18%, rgb(121, 168, 166) 44%, rgb(117, 163, 163) 49%, rgb(94, 140, 146) 74%, rgb(76, 122, 133) 94%);
    
}

.vertical-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#header-container {
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 1em;
    padding-left: 1em;
}

#title {
    padding: 0.2em;
    color: whitesmoke;
    font-family: "Cinzel Decorative", serif;
    font-size: 3.2em;
    display: inline;
}

#logo {
    display: inline-block;
    max-width: 3.2em;
    width: auto;
    padding-left: 0.1em;
}

#score-btn{
    display: none;
}


main {
    padding: 2rem 2rem 4rem 2rem;
    height: 100%;
}


/*NAVIGATION tabs */

#navigation {
    padding: 1rem;
    display: none;
}

#navigation a {
    font-size: 1.2em;
    color: #112d32;
}

/*--------------------- FOOTER ----------------------*/
footer {
    padding: 1rem;
    text-align: center;
    background-color: #6e6658;
    color: white;
    margin: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4rem;
}

#header-container, footer {

    background: rgb(110, 102, 89);
    background: -moz-linear-gradient(left, rgb(110, 102, 89) 0%, rgb(110, 102, 89) 4%, rgb(83, 78, 67) 88%, rgb(79, 74, 64) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgb(110, 102, 89)), color-stop(4%, rgb(110, 102, 89)), color-stop(88%, rgb(83, 78, 67)), color-stop(100%, rgb(79, 74, 64)));
    background: -webkit-linear-gradient(left, rgb(110, 102, 89) 0%, rgb(110, 102, 89) 4%, rgb(83, 78, 67) 88%, rgb(79, 74, 64) 100%);
    background: -o-linear-gradient(left, rgb(110, 102, 89) 0%, rgb(110, 102, 89) 4%, rgb(83, 78, 67) 88%, rgb(79, 74, 64) 100%);
    background: -ms-linear-gradient(left, rgb(110, 102, 89) 0%, rgb(110, 102, 89) 4%, rgb(83, 78, 67) 88%, rgb(79, 74, 64) 100%);
    background: linear-gradient(to right, rgb(110, 102, 89) 0%, rgb(110, 102, 89) 4%, rgb(83, 78, 67) 88%, rgb(79, 74, 64) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6e6659', endColorstr='#4f4a40', GradientType=1);

}
